import React from "react"
import { graphql } from "gatsby"
import ContactHero from "../components/ContactHero"
import Helmet from "react-helmet"
//internal
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const Contact = ({ data }) => {
  const InputStyle = "border p-2 bg-gray-100 rounded-sm mt-4"
  const BtnStyle =
    "px-5 py-3 font-medium leading-snug border border-transparent text-base rounded-md text-btncolor bg-white border-btncolor opacity-75 hover:opacity-100 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:truncate"

  return (
    <Layout>
      <SiteMetadata title="Zurly Resourses" description="Zurly contact form" />
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <link
          rel="alternate"
          type="application/json+oembed"
          href="https://www.jotform.com/oembed/?format=json&amp;url=https%3A%2F%2Fform.jotform.com%2F230512170837147"
          title="oEmbed Form"
        />
        <link
          rel="alternate"
          type="text/xml+oembed"
          href="https://www.jotform.com/oembed/?format=xml&amp;url=https%3A%2F%2Fform.jotform.com%2F230512170837147"
          title="oEmbed Form"
        />
        <meta property="og:title" content="Zurly Contact Form" />
        <meta
          property="og:url"
          content="https://form.jotform.com/230512170837147"
        />
        <meta
          property="og:description"
          content="Please click the link to complete this form."
        />
        <meta name="slack-app-id" content="AHNMASS8M" />
        <meta
          property="og:image"
          content="https://cdn.jotfor.ms/assets/img/landing/opengraph.png"
        />
        <meta
          property="og:image"
          content="https://cdn.jotfor.ms/assets/img/favicons/favicon-2021-light%402x.png"
        />
        <link
          rel="shortcut icon"
          href="https://cdn.jotfor.ms/assets/img/favicons/favicon-2021-light%402x.png"
        />
        <script>
          {`var favicon = document.querySelector('link[rel="shortcut icon"]');
        window.isDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
        if (favicon && window.isDarkMode) {
          favicon.href = favicon.href.replaceAll('favicon-2021-light%402x.png', 'favicon-2021-dark%402x.png');
        }`}
        </script>
        <link rel="canonical" href="https://form.jotform.com/230512170837147" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=1"
        />
        <meta name="HandheldFriendly" content="true" />
        <title>Zurly Contact Form</title>

        <script type="text/javascript">
          {`
          JotForm.showJotFormPowered = "0";
          JotForm.poweredByText = "Powered by Jotform";
        `}
        </script>
        {/* Add the hidden input field */}
        <input
          type="hidden"
          className="simple_spc"
          id="simple_spc"
          name="simple_spc"
          value="230512170837147"
        />
        {/* Add the script that sets the values of all .simple_spc fields */}
        <script type="text/javascript">
          {`
          var all_spc = document.querySelectorAll("form[id='230512170837147'] .si" + "mple" + "_spc");
          for (var i = 0; i < all_spc.length; i++) {
            all_spc[i].value = "230512170837147-230512170837147";
          }
        `}
        </script>
        <script
          src="https://cdn02.jotfor.ms/static/prototype.forms.js?3.3.39460"
          type="text/javascript"
        />
        <script
          src="https://cdn03.jotfor.ms/static/jotform.forms.js?3.3.39460"
          type="text/javascript"
        />
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/punycode/1.4.1/punycode.js"
        ></script>
        <script type="text/javascript">
          {`
      JotForm.newDefaultTheme = false;
      JotForm.extendsNewTheme = false;
      JotForm.singleProduct = true;
      JotForm.newPaymentUIForNewCreatedForms = false;

      JotForm.init(function () {
        /*INIT-START*/
        setTimeout(function () {
          $('input_10').hint('Email');
        }, 20);
        if (window.JotForm && JotForm.accessible) $('input_13').setAttribute('tabindex', 0);
        JotForm.setCustomHint('input_13', 'Type here...');
        JotForm.alterTexts({ "couponApply": "Apply", "couponBlank": "Please enter a coupon.", "couponChange": "", "couponEnter": "Enter coupon", "couponExpired": "Coupon is expired. Please try another one.", "couponInvalid": "Coupon is invalid.", "couponValid": "Coupon is valid.", "shippingShipping": "Shipping", "taxTax": "Tax", "totalSubtotal": "Subtotal", "totalTotal": "Total" }, true);
        /*INIT-END*/
      });
      JotForm.prepareCalculationsOnTheFly([null, null, null, null, null, { "name": "clickTo", "qid": "5", "text": "Get in Touch", "type": "control_head" }, null, null, null, { "description": "", "name": "name", "qid": "9", "text": "Name", "type": "control_fullname" }, { "description": "", "name": "email", "qid": "10", "subLabel": "example@example.com", "text": "Email", "type": "control_email" }, null, null, { "description": "", "name": "message", "qid": "13", "subLabel": "", "text": "Message", "type": "control_textarea" }, { "description": "", "name": "pleaseVerify", "qid": "14", "text": "Please verify that you are human", "type": "control_captcha" }, null, { "description": "", "name": "phoneNumber", "qid": "16", "text": "Phone Number", "type": "control_phone" }, null, { "name": "submit", "qid": "18", "text": "Submit", "type": "control_button" }]);
      setTimeout(function () {
        JotForm.paymentExtrasOnTheFly([null, null, null, null, null, { "name": "clickTo", "qid": "5", "text": "Get in Touch", "type": "control_head" }, null, null, null, { "description": "", "name": "name", "qid": "9", "text": "Name", "type": "control_fullname" }, { "description": "", "name": "email", "qid": "10", "subLabel": "example@example.com", "text": "Email", "type": "control_email" }, null, null, { "description": "", "name": "message", "qid": "13", "subLabel": "", "text": "Message", "type": "control_textarea" }, { "description": "", "name": "pleaseVerify", "qid": "14", "text": "Please verify that you are human", "type": "control_captcha" }, null, { "description": "", "name": "phoneNumber", "qid": "16", "text": "Phone Number", "type": "control_phone" }, null, { "name": "submit", "qid": "18", "text": "Submit", "type": "control_button" }]);
      }, 20); 
      `}
        </script>
      </Helmet>
      <ContactHero
        h1={data.hero.h1Text}
        category={data.hero.handwrittenText}
        paragraph={data.hero.paragraphText.paragraphText}
        image={data.hero.svgImage}
        alt={data.hero.svgImage.description}
        button={data.hero.buttonText}
      />
      <div className="container w-3/4 md:w-1/2">
        <form
          className="jotform-form"
          action="https://submit.jotform.com/submit/230512170837147/"
          method="post"
          name="form_230512170837147"
          id={230512170837147}
          acceptCharset="utf-8"
          autoComplete="on"
        >
          <input type="hidden" name="formID" defaultValue={230512170837147} />
          <input type="hidden" id="JWTContainer" defaultValue="" />
          <input type="hidden" id="cardinalOrderNumber" defaultValue="" />
          <div role="main" className="form-all">
            <ul className="form-section page-section">
              <div>
                <label
                  className="text-lg text-customgray"
                  id="label_9"
                  htmlFor="first_9"
                >
                  Name<span className="form-required">*</span>
                </label>
                <div id="cid_9" className="form-input jf-required">
                  <div data-wrapper-react="true">
                    <span
                      className="form-sub-label-container"
                      data-input-type="first"
                    >
                      <input
                        type="text"
                        id="first_9"
                        name="q9_name[first]"
                        className={`${InputStyle} w-full md:w-1/2 mr-6`}
                        data-defaultvalue=""
                        autoComplete="section-input_9 given-name"
                        size={10}
                        defaultValue=""
                        placeholder="First Name"
                        data-component="first"
                        aria-labelledby="label_9"
                        required=""
                      />
                    </span>
                  </div>
                  <br />
                  <div>
                    <label
                      className="text-lg text-customgray"
                      id="label_9"
                      htmlFor="first_9"
                    >
                      Last Name<span className="form-required">*</span>
                    </label>
                    <br />
                    <span
                      className="form-sub-label-container"
                      data-input-type="last"
                    >
                      <input
                        type="text"
                        id="last_9"
                        name="q9_name[last]"
                        className={`${InputStyle} w-full md:w-1/2 mr-6`}
                        data-defaultvalue=""
                        autoComplete="section-input_9 family-name"
                        size={15}
                        defaultValue=""
                        placeholder="Last Name"
                        data-component="last"
                        aria-labelledby="label_9"
                        required=""
                      />
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <li
                className="form-line jf-required"
                data-type="control_email"
                id="id_10"
              >
                <label
                  className="text-lg text-customgray"
                  id="label_10"
                  htmlFor="input_10"
                >
                  Email<span className="form-required">*</span>
                </label>
                <div id="cid_10" className="form-input jf-required">
                  <span className="form-sub-label-container">
                    <input
                      type="email"
                      id="input_10"
                      name="q10_email"
                      className={`${InputStyle} w-full md:w-1/2 mr-6`}
                      data-defaultvalue=""
                      size={30}
                      defaultValue=""
                      placeholder="Email"
                      data-component="email"
                      aria-labelledby="label_10 sublabel_input_10"
                      required=""
                    />
                  </span>
                </div>
              </li>
              <br />
              <li className="form-line" data-type="control_phone" id="id_16">
                <div id="cid_16" className="form-input">
                  <div data-wrapper-react="true" className="flex ">
                    <span
                      className="form-sub-label-container"
                      data-input-type="areaCode"
                    >
                      <label
                        className="text-lg text-customgray"
                        htmlFor="input_16_area"
                        id="sublabel_16_area"
                        style={{ minHeight: 13 }}
                        aria-hidden="false"
                      >
                        Area Code
                      </label>
                      <span className="phone-separate" aria-hidden="true">
                        &nbsp;-
                      </span>
                      <br />
                      <input
                        type="tel"
                        id="input_16_area"
                        name="q16_phoneNumber[area]"
                        className={`${InputStyle} w-full md:w-28 mr-4`}
                        data-defaultvalue=""
                        autoComplete="section-input_16 tel-area-code"
                        defaultValue=""
                        data-component="areaCode"
                        aria-labelledby="label_16 sublabel_16_area"
                      />
                      <br />
                    </span>
                    <div>
                      <label
                        className="text-lg text-customgray"
                        id="label_16"
                        htmlFor="input_16_area"
                      >
                        Phone Number
                      </label>
                      <br />
                      <span
                        className="form-sub-label-container"
                        data-input-type="phone"
                      >
                        <input
                          type="tel"
                          id="input_16_phone"
                          name="q16_phoneNumber[phone]"
                          className={`${InputStyle} w-full mr-16`}
                          data-defaultvalue=""
                          autoComplete="section-input_16 tel-local"
                          defaultValue=""
                          data-component="phone"
                          aria-labelledby="label_16 sublabel_16_phone"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </li>
              <br />
              <li className="form-line" data-type="control_textarea" id="id_13">
                <label
                  className="text-lg text-customgray"
                  id="label_13"
                  htmlFor="input_13"
                >
                  Message
                </label>
                <div id="cid_13" className="form-input">
                  <textarea
                    id="input_13"
                    className={`${InputStyle} w-full md:w-1/2 mr-6`}
                    name="q13_message"
                    cols={40}
                    rows={6}
                    data-component="textarea"
                    aria-labelledby="label_13"
                    defaultValue={""}
                  />
                </div>
              </li>
              {/* <li
                className="form-line jf-required"
                data-type="control_captcha"
                id="id_14"
              >
                <label  className="text-lg text-customgray" id="label_14" htmlFor="input_14">
                  Please verify that you are human
                  <span className="form-required">*</span>
                </label>
                <div id="cid_14" className="form-input jf-required">
                  <section data-wrapper-react="true">
                    <div
                      id="hcaptcha_input_14"
                      className="h-captcha"
                      data-sitekey="772f4a50-7161-425e-8cd5-4d7e361ab765"
                      data-callback="hcaptchaCallbackinput_14"
                      data-expired-callback="hcaptchaExpiredCallbackinput_14"
                    />
                    <input
                      type="hidden"
                      id="input_14"
                      className="hidden validate[required]"
                      name="hcaptcha_visible"
                      required=""
                    />
                  </section>
                </div>
              </li> */}
              <br />
              <li className="form-line" data-type="control_button" id="id_18">
                <div id="cid_18" className="form-input-wide">
                  <div
                    data-align="auto"
                    className="form-buttons-wrapper form-buttons-auto jsTest-button-wrapperField"
                  >
                    <button
                      id="input_18"
                      data-component="button"
                      data-content=""
                      className={BtnStyle}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </li>
              <li style={{ display: "none" }}>
                Should be Empty:
                <input type="text" name="website" defaultValue="" />
              </li>
            </ul>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    hero: contentfulHeros(handwrittenText: { eq: "LET'S GET STARTED" }) {
      handwrittenText
      h1Text
      buttonText
      paragraphText {
        paragraphText
      }
      svgImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
  }
`
